import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 style={{
      width: `100%`,
      textAlign: `center`,
      color: `#333333`,
      textTransform: `uppercase`,
      marginBottom: `0.4rem`
    }}>Sistema de Gestão<br/>Online</h1>
    <p style={{
      textAlign: `center`,
      fontWeight: `300`
    }}>
      Troque as planilhas por uma ferramenta<br/>
      mais segura, simples e inteligente.
    </p>
    <form action="https://app.convess.com/account-request/new"
        style={{
            textAlign: `center`
        }}
    >
      <input style={{
          height: `50px`,
          borderRadius: `2px`,
          border: `2px solid #A9A9A9`,
          padding: `1rem`,
          width: `15rem`,
          fontSize: `medium`
        }}
        name="email" placeholder="Seu e-mail"/>

      <button style={{
        textDecoration: `none`,
        marginTop: -1,
        padding: `0.5rem`,
        color: `white`,
        backgroundColor: `#228c7b`,
        fontSize: `small`,
        fontWeight: `600`,
        borderRadius: `2px`,
        border: `2px solid #228c7b`,
        marginLeft: `-2px`,
        height: "50px"
      }}>QUERO USAR</button>
    </form>
    <div style={{
      textAlign: `center`,
      marginTop: `5rem`
    }}>
      <p style={{
        display: `inline`,
        marginRight: `1rem`,
        fontWeight: 300,
        fontSize: `medium`
      }}>Já tem uma conta?</p>
      <a href="https://app.convess.com"
        style={{
          textDecoration: `none`,
          padding: `0.5rem`,
          color: `white`,
          backgroundColor: `#228c7b`,
          fontSize: `small`,
          fontWeight: `600`,
          borderRadius: `2px`,
        }}
      >
        ENTRAR
      </a>
    </div>
    <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,600,900&display=swap" rel="stylesheet" /> 
    </Helmet>
  </Layout>
)

export default IndexPage
